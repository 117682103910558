<template>
  <button
    class="u-flex u-flex-shrink-0 u-flex-align-center"
    :class="{
      'c-buy-button__logo': !onlyLogo,
      'c-buy-button__logo-icon c-button--no-styles': onlyLogo
    }"
    @click.prevent="saveVendor"
  >
    <Picture
      :src="model.logo"
      :resizer="model.imageResizer.name"
      :alt="model.title"
      :mobile="[100, 50]"
      :options="{ mode : 'max'}"
      :lazy="true"
    />
    <Icon
      v-if="!onlyLogo"
      class="u-flex-shrink-0 u-ml--a"
      :icon="iconArrow"
      :size="12"
      fill="medium-gray"
    />
  </button>
</template>

<script>
import iconArrow from '@ds/svg/icons/bold/arrow-right-1-bold.svg'
import Icon from '@/CVI/WebCore/components/Icon.vue'
import Picture from '@/CVI/WebCore/components/Picture.vue'
import store from '@/CVI/Purchase/store'

export default {
  components: {
    Picture,
    Icon
  },
  props: {
    model: {
      type: Object,
      default: () => {}
    },
    recipeUid: {
      type: String,
      default: ''
    },
    portionCount: {
      type: Number,
      default: 1
    },
    onlyLogo: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      iconArrow,
      store
    }
  },
  methods: {
    saveVendor() {
      if (window.shoppingCartLink) {
        this.store.dispatch('saveQuickVendorButtonClick')
        this.store.dispatch('saveVendor', { vendor: this.model, id: this.model.id })
        window.location.href = window.shoppingCartLink
        const { recipeUid: uid, portionCount } = this
        if (uid) {
          this.store.dispatch('addRecipesToCart', [{
            uid,
            portionCount
          }])
        }
      }
    }
  }
}
</script>
