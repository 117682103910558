<template>
  <span
    class="c-button--icon c-button--icon-round u-flex-align-center"
    role="button"
    :class="{
      'c-button--icon-white u-flex-justify-center u-flex-shrink-0': isButton
    }"
    :aria-pressed="active ? 'true' : 'false'"
    @click.prevent="onClick"
  >
    <Icon
      v-show="iconActive && active"
      class="c-action__bar-icon"
      :icon="iconActive"
      :hover-color="iconActiveHoverColor"
      fill="success"
    />

    <Icon
      v-show="!iconActive || !active"
      class="c-action__bar-icon"
      :icon="icon"
      :hover-color="iconHoverColor"
      :stroke="isButton ? 'black' : iconColor"
    />
  </span>
</template>

<script>
import Icon from '@/CVI/WebCore/components/Icon.vue'

export default {
  components: {
    Icon
  },
  props: {
    text: {
      type: String,
      default: ''
    },
    icon: {
      type: Object,
      required: true
    },
    iconActive: {
      type: Object,
      default: () => {}
    },
    iconColor: {
      type: String,
      default: 'black'
    },
    iconHoverColor: {
      type: String,
      default: ''
    },
    iconActiveHoverColor: {
      type: String,
      default: ''
    },
    active: {
      type: Boolean,
      default: false
    },
    showTitle: {
      type: Boolean,
      default: false
    },
    iconPlacement: {
      type: String,
      default: 'right'
    },
    isButton: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onClick() {
      this.$emit('click')
    }
  }
}
</script>
