<template>
  <div class="c-shopping-cart-panel__mealplan-card">
    <transition
      name="fade"
      mode="out-in"
    >
      <div v-if="!isRemoving">
        <transition
          name="fade"
        >
          <div
            v-if="!isLoading"
            class="c-shopping-cart-panel__recipe-remove"
          >
            <CloseButton
              :size="16"
              button-size="small"
              @click="scheduleRemoval"
            />
          </div>
        </transition>
        <RecipeCard
          :model="recipe"
          layout="horizontal"
          :arrow="false"
          :show-buy-button="false"
          :show-save-action="false"
          :style="{
            opacity: isLoading ? .3 : null
          }"
        />
        <span
          class="c-shopping-cart-panel__recipe-move u-flex u-flex-justify-center"
          :style="{
            opacity: isLoading ? .3 : null
          }"
        >
          <Icon
            :icon="iconMove"
            :size="24"
            fill="medium-gray"
          />
        </span>
      </div>
      <div
        v-else
        class="c-shopping-cart-panel__undo-remove u-flex u-flex-align-center u-flex-justify-center"
        @click="undo"
      >
        <Icon
          :icon="iconUndo"
          :size="24"
          class="u-mr--xs"
          fill="medium-gray"
        />
        {{ undoText }}
      </div>
    </transition>
  </div>
</template>

<script>
import iconRefreshArrows from '@ds/svg/icons/stroke/refresh-arrows.svg'
import iconMove from '@ds/svg/icons/bold/move-icon-bold.svg'
import iconUndo from '@ds/svg/icons/bold/undo-bold.svg'
import Icon from '@/CVI/WebCore/components/Icon.vue'
import CloseButton from '@/CVI/WebCore/components/CloseButton.vue'
import RecipeCard from '@/CVI/Recipe/components/RecipeCard.vue'
import purchaseStore from '@/CVI/Purchase/store'

export default {
  components: {
    Icon,
    CloseButton,
    RecipeCard
  },
  props: {
    recipe: {
      type: Object,
      required: true
    },
    undoText: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      iconRefreshArrows,
      iconMove,
      iconUndo,
      purchaseStore,
      isRemoved: false,
      isRemoving: false,
      removalTimeout: null
    }
  },
  beforeDestroy() {
    if (this.removalTimeout && !this.isRemoved) {
      this.remove()
    }
  },
  methods: {
    scheduleRemoval() {
      this.isRemoving = true
      this.removalTimeout = setTimeout(() => {
        this.remove()
      }, 3200)
    },
    remove() {
      this.isRemoved = true
      this.$emit('removed')
    },
    undo() {
      clearTimeout(this.removalTimeout)
      this.removalTimeout = null
      this.isRemoving = false
    }
  }
}
</script>
