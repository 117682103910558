<template>
  <div
    class="c-shopping-cart-panel__content u-flex u-flex-direction-column"
  >
    <div
      v-if="!isLoading"
      class="c-shopping-cart-panel__inner-side"
    >
      <div class=" u-flex u-flex-align-center u-mb--s">
        <strong
          class="u-font-size-h4 u-flex-shrink-0 u-m--0 u-ml--s"
        >
          {{ model.recipeInBasketText }} ({{ recipes.length }})
        </strong>
        <div class="c-shopping-cart-panel__mealplan-checkbox u-ml--a">
          <input
            id="mealplan-checkbox"
            v-model="isMealplanEnabled"
            type="checkbox"
          >
          <label
            for="mealplan-checkbox"
            class="c-shopping-cart-panel__mealplan-checkbox-label u-flex u-flex-align-center"
          >
            {{ model.mealPlan.labelText }}
          </label>
        </div>
      </div>
    </div>

    <Loader
      v-if="isLoading"
      size="large"
      color="medium-gray"
      class="u-ml--a u-mr--a"
    />
    <template v-else>
      <transition
        name="fade"
        mode="out-in"
      >
        <CartMealplan
          v-if="isMealplanEnabled && !undoShown"
          :recipes="recipes"
          :days="model.mealPlan.weekDays"
          :help-text="model.mealPlan.dragAndDropHelpText"
          :undo-text="model.mealPlan.undoButtonText"
          :tags="model.mealPlan.tagUids"
          :shuffle-text="model.mealPlan.shuffleRecipeHelpText"
          :placeholder-text="model.mealPlan.unsortedRecipesPlaceholder"
          :add-recipe-button="model.mealPlan.addRecipeButtonText"
          :search-recipe-link="model.mealPlan.searchRecipeLink"
          @replace-recipe="replaceRecipe"
          @remove-recipe="removeRecipeFromShoppingCart"
          @add-recipe="addRecipe"
          @dragging="dragging"
        />
        <div
          v-if="!recipes.length && !undoShown && !isMealplanEnabled"
          class="c-shopping-cart-panel__empty-cart u-flex-1 u-flex u-flex-align-center u-flex-justify-center u-font-size-large"
          v-html="model.noRecipesAddedHtml"
        />
        <UndoRemoveAll
          v-if="undoShown"
          :model="model.shoppingList"
          @undo-clear-cart="undoClearCart"
        />
        <Recipes
          v-if="!isMealplanEnabled && recipes.length && !undoShown"
          :recipes="recipes"
          @remove-recipe="removeRecipeFromShoppingCart"
        />
      </transition>
      <div
        v-if="($mq == 'mobile' && !isDragging) || $mq != 'mobile'"
        class="c-shopping-cart-panel__bottom"
      >
        <div class="c-shopping-cart-panel__bottom-inner u-flex u-flex-align-center">
          <button
            v-if="recipes.length"
            class="c-button--icon-large c-button--no-styles"
            @click="clearCart()"
          >
            <Icon
              :icon="iconTrash"
              :size="24"
              fill="dark"
            />
          </button>
          <a
            class="c-button c-button--green u-flex-shrink-0  u-ml--a"
            :href="model.shoppingListUrl"
            @click="trackBuyButtonClick"
          >
            <Icon
              class="u-mr--xs"
              :icon="iconCart"
              :size="16"
              :stroke="'white'"
            />
            {{ model.seeBasketText }}
          </a>
        </div>
        <div class="c-shopping-card__buy-button u-flex u-flex-wrap u-flex-align-center">
          <VendorButton
            v-for="vendor in vendors"
            :key="vendor.title"
            class="u-mb--xxs u-mr--xs"
            :model="vendor"
            :only-logo="true"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { enablePageScroll } from 'scroll-lock'
import iconTrash from '@ds/svg/icons/bold/bin-1-bold.svg'
import iconArrow from '@ds/svg/icons/stroke/keyboard-arrow-right.svg'
import iconCart from '@ds/svg/icons/stroke/shopping-basket-1.svg'
import store from '@/CVI/WebCore/store'
import api from '@/CVI/Purchase/lib/api'
import recipeApi from '@/CVI/Recipe/lib/api'
import purchaseStore from '@/CVI/Purchase/store'

import VendorButton from '@/CVI/Purchase/components/VendorButton.vue'
import Icon from '@/CVI/WebCore/components/Icon.vue'
import Loader from '@/CVI/WebCore/components/Loader.vue'
import tracker from '@/CVI/WebCore/core-tracker'
import CartMealplan from '@/CVI/Purchase/components/ShoppingCart/CartMealplan.vue'
import Recipes from '@/CVI/Purchase/components/ShoppingCart/Recipes.vue'
import UndoRemoveAll from '@/CVI/Purchase/components/ShoppingCart/UndoRemoveAll.vue'

export default {
  components: {
    CartMealplan,
    VendorButton,
    Icon,
    Loader,
    Recipes,
    UndoRemoveAll
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data() {
    const { isMealplanEnabled } = purchaseStore.state
    return {
      iconArrow,
      recipes: [],
      vendors: [],
      store,
      isOpened: false,
      purchaseStore,
      isLoading: true,
      iconCart,
      iconTrash,
      isMealplanEnabled,
      undoShown: false,
      isDragging: false
    }
  },
  watch: {
    isMealplanEnabled: {
      handler(value) {
        this.purchaseStore.dispatch('setMealPlanState', { value })
      }
    }
  },
  async mounted() {
    this.loadRecipes()
    const { data: vendors } = await api.vendors.get()
    this.vendors = vendors
    this.isOpened = true
  },
  destroyed() {
    if (!this.recipes.length) {
      this.purchaseStore.dispatch('saveMealplan', { mealplan: [] })
    }
    enablePageScroll()
    this.isOpened = false
    this.undoShown = false
  },
  methods: {
    removeRecipeFromShoppingCart({ recipeUid }) {
      const recipeToRemove = this.recipes.find(({ uid }) => recipeUid == uid)
      this.recipes.splice(this.recipes.indexOf(recipeToRemove), 1)
      this.purchaseStore.dispatch('removeFromCart', {
        recipeUid: recipeToRemove.uid
      })
    },
    replaceRecipe({ oldRecipeUid, recipe }) {
      const recipeToReplaceIndex = this.recipes.findIndex(({ uid }) => oldRecipeUid == uid)
      const { uid, buyButton: { portionCount } } = recipe
      this.recipes[recipeToReplaceIndex] = {
        ...recipe
      }
      this.purchaseStore.dispatch('replaceRecipe', {
        oldRecipeUid,
        recipe: { uid, portionCount }
      })
    },
    clearCart() {
      const { recipes } = this
      this.savedRecipes = [...recipes]
      this.undoShown = true
      this.recipes = []
      this.purchaseStore.dispatch('clearCart')
    },
    undoClearCart() {
      const { savedRecipes } = this
      this.purchaseStore.dispatch('addRecipesToCart', [...savedRecipes])
      this.$nextTick(() => {
        this.recipes = [...savedRecipes]
        this.$nextTick(() => {
          this.undoShown = false
        })
      })
    },
    async loadRecipes() {
      this.isLoading = true
      const uids = this.purchaseStore.state.cart.map(({ uid }) => uid)
      const { data: recipes } = await recipeApi.recipes.get(uids)
      this.recipes = recipes
      this.isLoading = false
    },
    addRecipe({ recipe }) {
      this.recipes.push(recipe)
    },
    trackBuyButtonClick() {
      const { isMealplanEnabled, mealplan } = this.purchaseStore.state
      tracker.track({
        event: 'menuNavigationShoppingCartShow',
        mealplanToggle: isMealplanEnabled ? 'on' : 'off',
        numberOfRecipes: mealplan.length
      })
    },
    dragging(isDragging) {
      this.isDragging = isDragging
    }
  }
}
</script>
