<template>
  <Popover
    v-model="isPopoverVisible"
    class="c-action-bar__item"
    :auto-close="!popoverHasActionElements"
    :placement="$mq == 'desktop' ? popoverPosition : popoverPositionMobile"
    @click.native.stop
  >
    <ActionBarItem
      class="c-action-bar__save-action"
      :text="model.saveItemLabel"
      :icon="iconLoveIt"
      :icon-size="iconSize"
      :icon-active="iconLoveItBold"
      :active="isFavorite"
      :show-title="showTitle"
      :icon-hover-color="'medium-gray'"
      :icon-active-hover-color="'success'"
      :icon-color="iconColor"
      :is-button="isButton"
      @click.native.prevent="toggleFavorite"
    />

    <template slot="popover">
      <SavePopup
        :model="model"
        :is-favorite="isFavorite"
        @close="close"
      />
    </template>
  </Popover>
</template>

<script>
import iconLoveIt from '@ds/svg/icons/stroke/love-it.svg'
import iconLoveItBold from '@ds/svg/icons/bold/love-it-bold.svg'
import store from '@/CVI/Recipe/store'

import tracker from '@/CVI/WebCore/core-tracker'

import Popover from '@/CVI/WebCore/components/Popover.vue'
import ActionBarItem from './ActionBarItem.vue'
import SavePopup from './SavePopup.vue'

export default {
  components: {
    Popover,
    ActionBarItem,
    SavePopup
  },
  props: {
    value: {
      type: Boolean,
      default: undefined
    },
    model: {
      type: Object,
      required: true
    },
    iconColor: {
      type: String,
      default: 'black'
    },
    iconSize: {
      type: Number,
      default: 24
    },
    showTitle: {
      type: Boolean,
      default: false
    },
    popoverPosition: {
      type: String,
      default: 'bottom'
    },
    popoverPositionMobile: {
      type: String,
      default: 'bottom'
    },
    trackingObj: {
      type: Object,
      default: null
    },
    recipeName: {
      type: String,
      default: ''
    },
    isButton: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      store,
      iconLoveIt,
      iconLoveItBold,
      isSaved: this.model.isFavorite,
      isPopoverVisible: false
    }
  },
  computed: {
    popoverHasActionElements() {
      return !this.model.isAuthenticated && this.isFavorite
    },
    isFavorite: {
      get() {
        return this.value !== undefined ? this.value : this.isSaved
      }
    }
  },
  methods: {
    async toggleFavorite(event) {
      this.isPopoverVisible = false
      const { isFavorite } = this
      const { recipeId } = this.model

      if (isFavorite) {
        await this.store.dispatch('removeRecipe', { recipeId })
      } else {
        await this.store.dispatch('saveRecipe', { recipeId })
        this.trackData()
      }
      this.isPopoverVisible = true
      this.isSaved = !isFavorite
      this.$emit('input', !isFavorite)
      if (!isFavorite) {
        const customEvent = new CustomEvent('saveActionClicked', {
          bubbles: true,
          detail: event
        })
        document.body.dispatchEvent(customEvent)
      }
    },
    trackData() {
      const { trackingObj } = this
      const track = {
        ...trackingObj
      }
      // Meal plan tracking requires recipe name
      if (this.recipeName) {
        track.recipeName = this.recipeName
      } else {
        track.position = this.showTitle ? 'sticky' : 'top'
      }
      tracker.track(track)
    },
    close() {
      this.isPopoverVisible = false
    }
  }
}
</script>
