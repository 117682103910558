<template>
  <component
    :is="recipePreview ? 'div' : 'a'"
    :class="cardClass"
    :href="recipePreview ? null : model.url"
  >
    <Picture
      :src="model.picture"
      :resizer="model.imageResizer.name"
      :class="imageClass"
      :alt="model.title"
      :desktop="desktop"
      :tablet="tablet"
      :mobile="mobile"
      :show-load-state="showImageLoadState"
      :lazy="true"
      :options="{
        mode: 'crop'
      }"
      :style="{
        opacity: showLoader ? 0 : 1
      }"
    />
    <div
      v-if="showLoader"
      class="c-card__loader-wrap"
    >
      <span class="c-card__loader">
        <Loader
          size="large"
          color="medium-gray"
        />
      </span>
    </div>

    <div
      class="c-card__content"
      :class="{
        'c-card__content--large': size == 'large'
      }"
    >
      <span
        v-if="model.showAsNew"
        class="c-card__image-label c-label-new"
      >
        {{ model.showAsNewLabel }}
      </span>
      <div
        :class="cookingTimeContainerClass"
      >
        <span
          :class="cookingTimeClass"
        >
          {{ model.cookingTimeText }}
        </span>
      </div>
      <div
        v-if="!recipePreview"
        class="c-card__actions"
      >
        <BuyButton
          v-if="showBuyButton && buyButtonModel"
          class="u-mb--xs"
          :model="buyButtonModel"
        />
        <RecipeSaveAction
          v-if="model.enableActionSave && showSaveAction"
          :model="model.actionSave"
          icon-color="white"
          :is-button="true"
          :tracking-obj="saveActionTracking"
          :recipe-name="model.title"
        />
      </div>
      <strong
        :class="titleClass"
      >{{ model.title }}</strong>
      <RatingStatic
        v-if="showRating"
        :rating="model.ratingAverageRounded"
        :count="model.ratingCount"
        :size="size"
        :show-rating-count="showRatingCount"
      />
    </div>
    <div
      v-if="arrow"
      class="u-hidden-print"
      :class="arrowClass"
    >
      <Icon
        :icon="iconArrow"
        :size="arrowSize"
        fill="black"
      />
    </div>
  </component>
</template>

<script>

import iconArrow from '@ds/svg/icons/bold/arrow-right-1-bold.svg'
import Icon from '@/CVI/WebCore/components/Icon.vue'
import RatingStatic from '@/CVI/WebCore/components/RatingStatic.vue'
import Picture from '@/CVI/WebCore/components/Picture.vue'
import RecipeSaveAction from '@/CVI/Recipe/components/SaveAction.vue'
import Loader from '@/CVI/WebCore/components/Loader.vue'
import BuyButton from '@/CVI/Purchase/components/BuyButton.vue'

export default {
  components: {
    BuyButton,
    Icon,
    RatingStatic,
    Picture,
    RecipeSaveAction,
    Loader
  },
  props: {
    model: {
      type: Object,
      default: () => {}
    },
    arrow: {
      type: Boolean,
      default: true
    },
    layout: {
      type: String,
      default: 'vertical'
    },
    size: {
      type: String,
      default: 'small'
    },
    showSaveAction: {
      type: Boolean,
      default: true
    },
    desktop: {
      type: Array,
      required: false,
      default: () => [76, 76]
    },
    tablet: {
      type: Array,
      required: false,
      default: () => [76, 76]
    },
    mobile: {
      type: Array,
      required: false,
      default: () => [76, 76]
    },
    showImageLoadState: {
      type: Boolean,
      default: false
    },
    showLoader: {
      type: Boolean,
      default: false
    },
    saveActionTracking: {
      type: Object,
      default: null
    },
    recipePreview: {
      type: Boolean,
      default: false
    },
    showRatingCount: {
      type: Boolean,
      default: true
    },
    showRating: {
      type: Boolean,
      default: true
    },
    showBuyButton: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      iconArrow
    }
  },
  computed: {
    cardClass() {
      return this.layout == 'horizontal' ? 'c-card c-card--horizontal' : 'c-card c-card--vertical'
    },
    imageClass() {
      return this.size == 'large' ? 'c-card__image c-card__image--large' : 'c-card__image'
    },
    cookingTimeClass() {
      return ['c-label--s', 'u-text-dark-gray', 'u-font-bold']
    },
    cookingTimeContainerClass() {
      const classArr = ['c-card__cooking-time', 'u-mb--xxs']
      if (this.layout == 'horizontal' && !this.model.cookingTimeText) {
        classArr.push('u-hidden')
      }
      return classArr
    },
    titleClass() {
      const classArr = ['c-card__title', 'u-text-break', 'u-mr--s']
      if (this.size == 'large') {
        classArr.push('u-mb--xs')
        classArr.push('u-font-size-h4')
      } else {
        classArr.push('u-mb--xxs')
        classArr.push('u-font-size-h6')
      }
      return classArr
    },
    arrowClass() {
      return this.size == 'large' ? 'c-card__arrow c-card__arrow--large' : 'c-card__arrow'
    },
    arrowSize() {
      return this.size == 'large' ? 24 : 16
    },
    buyButtonModel() {
      if (this.model.buyButton) {
        const {
          uid, id, portionCount = 2, trackingData
        } = this.model.buyButton
        trackingData.eventAction = 'Add to basket from tile'
        return {
          uid: uid || id,
          portionCount,
          trackingData
        }
      }
      return false
    }
  }
}
</script>
