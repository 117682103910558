<template>
  <div
    ref="container"
    class="c-shopping-cart-panel__recipes"
  >
    <transition-group
      tag="div"
      name="fade"
      mode="out-in"
    >
      <div
        v-for="recipe in recipes"
        :key="recipe.uid"
        class="c-shopping-cart-panel__recipe u-mb--s"
      >
        <div class="c-shopping-cart-panel__recipe-remove">
          <CloseButton
            :size="16"
            button-size="small"
            @click="removeRecipe(recipe.uid, recipe.title)"
          />
        </div>
        <RecipeCard
          :model="recipe"
          layout="horizontal"
          :arrow="false"
          :show-buy-button="false"
          :show-save-action="false"
          @click.native="trackRecipeClick(model.title)"
        />
      </div>
    </transition-group>
  </div>
</template>

<script>
import { disablePageScroll, enablePageScroll } from 'scroll-lock'
import CloseButton from '@/CVI/WebCore/components/CloseButton.vue'
import RecipeCard from '@/CVI/Recipe/components/RecipeCard.vue'
import tracker from '@/CVI/WebCore/core-tracker'

export default {
  components: {
    CloseButton,
    RecipeCard
  },
  props: {
    recipes: {
      type: Array,
      default: () => []
    }
  },
  mounted() {
    this.$nextTick(() => {
      disablePageScroll(this.$refs.container)
    })
  },
  beforeDestroy() {
    enablePageScroll()
  },
  methods: {
    removeRecipe(uid, title) {
      this.$emit('remove-recipe', { recipeUid: uid })
      tracker.trackRemoveItemShoppingCart({
        event: 'menuNavigationShoppingCartRecipeClose',
        deleteSavedRecipeDropdown: title
      })
    },
    trackRecipeClick(name) {
      tracker.trackClickItemShoppingCart({
        event: 'menuNavigationShoppingCartRecipe',
        shoppingCartMenuRecipe: name
      })
    }
  }
}
</script>
