var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"container",staticClass:"c-shopping-cart-panel__recipes",class:{
    'no-dragging': !_vm.dragging
  }},[_c('div',{staticClass:"c-shopping-cart-panel__dragging-placeholder",class:{
      'is-empty u-mb--s': !_vm.unsortedRecipes.length || _vm.dragging
    }},[_c('draggable',{attrs:{"delay":120,"direction":"vertical","empty-insert-threshold":40,"swap-threshold":0.5,"list":_vm.unsortedRecipes,"group":"recipes","delay-on-touch-only":true,"handle":".c-card"},on:{"start":_vm.startDragging,"end":_vm.saveState}},_vm._l((_vm.unsortedRecipes),function(recipe,index){return _c('div',{key:recipe.uid,staticClass:"c-shopping-cart-panel__recipe u-mb--s"},[(index == 0 && _vm.isHelpTextShown)?_c('span',{staticClass:"c-shopping-cart-panel__help-text-placement"},[_c('Popover',{attrs:{"auto-close":true,"placement":"bottom"},model:{value:(_vm.isHelpTextShown),callback:function ($$v) {_vm.isHelpTextShown=$$v},expression:"isHelpTextShown"}},[_c('template',{slot:"popover"},[_c('div',{staticClass:"u-flex"},[_c('Icon',{staticClass:"u-mr--s",attrs:{"icon":_vm.iconExpand,"size":24,"stroke":"black"}}),_c('div',[_vm._v(" "+_vm._s(_vm.helpText)+" ")])],1)])],2)],1):_vm._e(),_c('MealplanCard',{attrs:{"recipe":recipe,"undo-text":_vm.undoText},on:{"removed":function($event){return _vm.removeRecipe(recipe.uid)}}})],1)}),0),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(!_vm.unsortedRecipes.length)?_c('div',{staticClass:"c-shopping-cart-panel__dragging-placeholder-text u-flex u-flex-align-center u-flex-justify-center",class:{
          'is-dragging': _vm.dragging
        }},[_c('Icon',{staticClass:"u-mr--s",attrs:{"icon":_vm.iconBasketQue,"size":24,"stroke":"black"}}),_c('span',{staticClass:"u-font-size-medium"},[_vm._v(" "+_vm._s(_vm.placeholderText)+" ")])],1):_vm._e()])],1),_vm._l((_vm.mealplan),function(day,index){return _c('div',{key:day.day},[_c('span',{staticClass:"c-label--m u-flex-inline u-mb--xs"},[_vm._v(_vm._s(day.day))]),_c('div',{staticClass:"c-shopping-cart-panel__dragging-placeholder-day u-mb--s",class:{
        'is-empty': !day.recipes.length || _vm.dragging,
        'is-dragging': _vm.dragging
      }},[(!day.recipes.length && !_vm.dragging)?_c('div',{staticClass:"c-shopping-cart-panel__additional-actions u-flex u-flex-align-center",class:{
          'is-loading': _vm.addingRecipe && _vm.addingRecipeIndex == index
        }},[(_vm.addingRecipe && _vm.addingRecipeIndex == index)?_c('Loader',{staticClass:"c-shopping-cart-panel__additional-actions-loader",attrs:{"size":"medium","color":"medium-gray"}}):[(_vm.searchRecipeLink)?_c('a',{staticClass:"c-button--no-styles u-text-nounderline u-width-50 u-flex-inline u-flex-direction-column u-flex-align-center u-flex-justify-center u-ml--a u-mr--a",attrs:{"href":_vm.searchRecipeLink.url}},[_c('Icon',{staticClass:"u-mt--xxs u-mb--xxs",attrs:{"icon":_vm.iconSearch,"size":24,"stroke":"black"}}),_c('span',{staticClass:"u-font-size-medium"},[_vm._v(_vm._s(_vm.searchRecipeLink.text))])],1):_vm._e()]],2):_vm._e(),_c('draggable',{attrs:{"delay":120,"empty-insert-threshold":40,"direction":"vertical","swap-threshold":0.5,"list":day.recipes,"delay-on-touch-only":true,"group":"recipes","handle":".c-card"},on:{"start":_vm.startDragging,"end":_vm.saveState}},_vm._l((day.recipes),function(recipe){return _c('div',{key:recipe.uid,staticClass:"c-shopping-cart-panel__recipe c-shopping-cart-panel__mealplan-recipe"},[_c('MealplanCard',{attrs:{"recipe":recipe,"undo-text":_vm.undoText},on:{"removed":function($event){return _vm.removeMealplanRecipe(recipe.uid, index)}}})],1)}),0)],1)])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }