import Cookies from 'js-cookie'
import api from '@/CVI/Recipe/lib/api'

const COMPLETED_INGREDIENTS = 'Recipe.CompletedIngredients'
const COMPLETED_INSTRUCTIONS = 'Recipe.CompletedInstructions'

const store = {
  state: {
    isFavorite: false,
    portionCount: 0,
    get completedIngredients() {
      const savedCookie = Cookies.get(COMPLETED_INGREDIENTS)
      return savedCookie ? savedCookie.split(',') : []
    },
    set completedIngredients(value) {
      Cookies.set(COMPLETED_INGREDIENTS, value.join(','), { expires: 1, path: '' })
    },
    get completedInstructions() {
      const savedCookie = Cookies.get(COMPLETED_INSTRUCTIONS)
      return savedCookie ? savedCookie.split(',') : []
    },
    set completedInstructions(value) {
      Cookies.set(COMPLETED_INSTRUCTIONS, value.join(','), { expires: 1, path: '' })
    },
    exps: {
      showBuyAction: false
    }
  },
  actions: {
    setCompletedInstructions({ state }, { instructions }) {
      state.completedInstructions = instructions
    },
    setCompletedIngredients({ state }, { ingredients }) {
      state.completedIngredients = ingredients
    },
    async saveRecipe({ state }, { recipeId }) {
      await api.favoriteRecipes.post(recipeId)
    },
    async removeRecipe({ state }, { recipeId }) {
      await api.favoriteRecipes.delete(recipeId)
    }
  }
}

// export simple vuex-like syntax
export default {
  state: store.state,
  getters: Object.keys(store.getters || {}).reduce((getters, key) => {
    Object.defineProperty(getters, key, {
      get() {
        return store.getters[key](store.state, getters)
      }
    })
    return getters
  }, {}),
  dispatch(action, payload) {
    store.actions[action](this, payload)
  }
}
