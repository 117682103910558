<template>
  <div
    ref="container"
    class="c-shopping-cart-panel__recipes"
    :class="{
      'no-dragging': !dragging
    }"
  >
    <div
      class="c-shopping-cart-panel__dragging-placeholder"
      :class="{
        'is-empty u-mb--s': !unsortedRecipes.length || dragging
      }"
    >
      <draggable
        :delay="120"
        direction="vertical"
        :empty-insert-threshold="40"
        :swap-threshold="0.5"
        :list="unsortedRecipes"
        group="recipes"
        :delay-on-touch-only="true"
        handle=".c-card"
        @start="startDragging"
        @end="saveState"
      >
        <div
          v-for="(recipe, index) in unsortedRecipes"
          :key="recipe.uid"
          class="c-shopping-cart-panel__recipe u-mb--s"
        >
          <span
            v-if="index == 0 && isHelpTextShown"
            class="c-shopping-cart-panel__help-text-placement"
          >
            <Popover
              v-model="isHelpTextShown"
              :auto-close="true"
              placement="bottom"
            >
              <template slot="popover">
                <div class="u-flex">
                  <Icon
                    :icon="iconExpand"
                    :size="24"
                    class="u-mr--s"
                    stroke="black"
                  />
                  <div>
                    {{ helpText }}
                  </div>
                </div>
              </template>
            </Popover>
          </span>
          <MealplanCard
            :recipe="recipe"
            :undo-text="undoText"
            @removed="removeRecipe(recipe.uid)"
          />
        </div>
      </draggable>
      <transition
        name="fade"
        mode="out-in"
      >
        <div
          v-if="!unsortedRecipes.length"
          class="c-shopping-cart-panel__dragging-placeholder-text u-flex u-flex-align-center u-flex-justify-center"
          :class="{
            'is-dragging': dragging
          }"
        >
          <Icon
            class="u-mr--s"
            :icon="iconBasketQue"
            :size="24"
            stroke="black"
          />
          <span class="u-font-size-medium">
            {{ placeholderText }}
          </span>
        </div>
      </transition>
    </div>
    <div
      v-for="(day, index) in mealplan"
      :key="day.day"
    >
      <span class="c-label--m u-flex-inline u-mb--xs">{{ day.day }}</span>
      <div
        class="c-shopping-cart-panel__dragging-placeholder-day u-mb--s"
        :class="{
          'is-empty': !day.recipes.length || dragging,
          'is-dragging': dragging
        }"
      >
        <div
          v-if="!day.recipes.length && !dragging"
          class="c-shopping-cart-panel__additional-actions u-flex u-flex-align-center"
          :class="{
            'is-loading': addingRecipe && addingRecipeIndex == index
          }"
        >
          <Loader
            v-if="addingRecipe && addingRecipeIndex == index"
            class="c-shopping-cart-panel__additional-actions-loader"
            size="medium"
            color="medium-gray"
          />
          <template v-else>
            <a
              v-if="searchRecipeLink"
              class="c-button--no-styles u-text-nounderline u-width-50 u-flex-inline u-flex-direction-column u-flex-align-center u-flex-justify-center u-ml--a u-mr--a"
              :href="searchRecipeLink.url"
            >
              <Icon
                class="u-mt--xxs u-mb--xxs"
                :icon="iconSearch"
                :size="24"
                stroke="black"
              />
              <span class="u-font-size-medium">{{ searchRecipeLink.text }}</span>
            </a>
          </template>
        </div>
        <draggable
          :delay="120"
          :empty-insert-threshold="40"
          direction="vertical"
          :swap-threshold="0.5"
          :list="day.recipes"
          :delay-on-touch-only="true"
          group="recipes"
          handle=".c-card"
          @start="startDragging"
          @end="saveState"
        >
          <div
            v-for="recipe in day.recipes"
            :key="recipe.uid"
            class="c-shopping-cart-panel__recipe c-shopping-cart-panel__mealplan-recipe"
          >
            <MealplanCard
              :recipe="recipe"
              :undo-text="undoText"
              @removed="removeMealplanRecipe(recipe.uid, index)"
            />
          </div>
        </draggable>
      </div>
    </div>
  </div>
</template>

<script>
import { disablePageScroll, enablePageScroll } from 'scroll-lock'
import draggable from 'vuedraggable'
import iconExpand from '@ds/svg/icons/stroke/expand-all-direction.svg'
import iconBasketQue from '@ds/svg/icons/stroke/basket-que.svg'
import iconRefreshArrows from '@ds/svg/icons/stroke/refresh-arrows.svg'
import iconSearch from '@ds/svg/icons/stroke/search.svg'

import Icon from '@/CVI/WebCore/components/Icon.vue'
import purchaseStore from '@/CVI/Purchase/store'
import Popover from '@/CVI/WebCore/components/Popover.vue'
import MealplanCard from '@/CVI/Purchase/components/ShoppingCart/MealplanCard.vue'
import Loader from '@/CVI/WebCore/components/Loader.vue'

export default {
  components: {
    draggable,
    Icon,
    Popover,
    Loader,
    MealplanCard
  },
  props: {
    recipes: {
      type: Array,
      default: () => []
    },
    tags: {
      type: Array,
      default: () => []
    },
    days: {
      type: Array,
      required: true
    },
    helpText: {
      type: String,
      required: true
    },
    undoText: {
      type: String,
      required: true
    },
    placeholderText: {
      type: String,
      required: true
    },
    searchRecipeLink: {
      type: Object,
      required: true
    },
    addRecipeButton: {
      type: String,
      required: true
    }
  },
  data() {
    const { recipes, days } = this
    const savedMealplan = purchaseStore.state.mealplan
    const unsortedRecipes = recipes
      .filter(({ uid }) => !savedMealplan.find(recipe => recipe.uid == uid))

    const mealplan = days.map((day) => {
      const mealplanRecipes = savedMealplan.filter(recipe => recipe.day == day)

      return {
        day,
        recipes: mealplanRecipes.map(({ uid }) => recipes.find(recipe => recipe.uid == uid)).filter(recipe => recipe)
      }
    })

    return {
      iconSearch,
      iconRefreshArrows,
      iconBasketQue,
      iconExpand,
      isHelpTextShown: !purchaseStore.state.mealplanDragDropHint,
      unsortedRecipes,
      addingRecipeIndex: -1,
      addingRecipe: false,
      dragging: false,
      purchaseStore,
      mealplan
    }
  },
  watch: {
    recipes(recipes) {
      if (recipes.length) {
        const { mealplan } = purchaseStore.state
        this.unsortedRecipes = recipes
          .filter(({ uid }) => !mealplan.find(recipe => recipe.uid == uid))
      } else {
        this.unsortedRecipes = []
        this.mealplan = this.mealplan.map(({ day }) => ({ day, recipes: [] }))
      }
    },
    mealplan() {
      this.saveState()
    },
    dragging(dragging) {
      this.$emit('dragging', dragging)
    }
  },
  mounted() {
    if (this.isHelpTextShown) {
      setTimeout(() => {
        this.isHelpTextShown = false
        this.purchaseStore.dispatch('hideMealPlanDragDropHint')
      }, 3000)
    }
    this.$nextTick(() => {
      disablePageScroll(this.$refs.container)
    })
  },
  beforeDestroy() {
    enablePageScroll()
  },
  methods: {
    removeMealplanRecipe(recipeUid, dayIndex) {
      const { mealplan } = this
      const { recipes } = mealplan[dayIndex]
      const recipeToRemove = recipes.find(({ uid }) => recipeUid == uid)
      mealplan[dayIndex].recipes.splice(recipes.indexOf(recipeToRemove), 1)
      this.removeRecipe(recipeUid)
      this.saveState()
    },
    replaceMealplanRecipe(recipeUid, dayIndex, recipe) {
      const { mealplan } = this
      const { recipes } = mealplan[dayIndex]
      const recipeToReplaceIndex = recipes.findIndex(({ uid }) => recipeUid == uid)
      recipes[recipeToReplaceIndex] = recipe
      mealplan[dayIndex].recipes = [...recipes]
      this.replaceRecipe(recipeUid, recipe)
      this.saveState()
    },
    removeRecipe(recipeUid) {
      this.$emit('remove-recipe', { recipeUid })
    },
    replaceRecipe(oldRecipeUid, recipe) {
      this.$emit('replace-recipe', { oldRecipeUid, recipe })
    },
    saveState() {
      this.dragging = false
      const { mealplan } = this
      this.purchaseStore.dispatch('saveMealplan', { mealplan })
    },
    startDragging() {
      this.dragging = true
    }
  }
}
</script>
