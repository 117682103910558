<template>
  <div>
    <strong class="c-popover__title u-flex u-m--0 u-mr--m">
      <Icon
        :icon="iconCheckBold"
        :size="16"
        fill="success"
        class="u-mr--xs"
      />
      {{ title }}
    </strong>

    <template v-if="isFavorite && !model.isAuthenticated">
      <p class="u-m--0 u-mt--xs u-mr--m">
        {{ saveActionSettings.loginToSaveText }}
      </p>
      <div class="u-flex u-flex-align-center u-mt--s">
        <a
          class="c-button c-button--small c-button--dark u-mr--xs"
          :href="model.loginPageUrl"
          @click.prevent="showModalPopup"
        >
          {{ saveActionSettings.loginLinkText }}
        </a>
      </div>
    </template>
  </div>
</template>

<script>
import iconCheckBold from '@ds/svg/icons/bold/check-1-bold.svg'
import Icon from '@/CVI/WebCore/components/Icon.vue'
import store from '@/CVI/WebCore/store'

export default {
  components: {
    Icon
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    isFavorite: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      store,
      iconCheckBold,
      saveActionSettings: window.configuration.recipeActionSavePopup
    }
  },
  computed: {
    title() {
      return this.isFavorite
        ? this.saveActionSettings.recipeSavedText
        : this.saveActionSettings.recipeRemovedText
    }
  },
  methods: {
    showModalPopup() {
      this.store.state.loginReturnUrl = this.saveActionSettings.profilePageUrl
      this.store.state.isLoginPopupVisible = true
      this.$emit('close')
    }
  }
}

</script>
