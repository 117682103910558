<template>
  <div class="c-buy-button u-flex u-flex-wrap u-hidden-print">
    <button
      class="c-buy-button__button
        c-button--no-styles
        c-button--icon
        u-flex-align-center
        u-flex-justify-center
        c-button--icon-round c-button--icon-green u-flex-shrink-0 u-hidden-print
      "
      @click.prevent.stop="onButtonClick"
    >
      <Icon
        class="c-buy-button__icon"
        :icon="iconCart"
        :stroke="'white'"
      />
    </button>
  </div>
</template>

<script>
import iconCart from '@ds/svg/icons/stroke/shopping-basket-1.svg'
import store from '@/CVI/Purchase/store'
import webcoreStore from '@/CVI/WebCore/store'
import tracker from '@/CVI/WebCore/core-tracker'

import Icon from '@/CVI/WebCore/components/Icon.vue'

export default {
  components: {
    Icon
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    size: {
      type: String,
      default: 'normal'
    }
  },
  data() {
    return {
      store,
      iconCart,
      webcoreStore
    }
  },
  methods: {
    onButtonClick(event) {
      const { uid, portionCount, trackingData } = this.model
      this.store.dispatch('addRecipesToCart', [{
        uid, portionCount
      }])
      this.webcoreStore.state.isShoppingPanelVisible = true
      if (trackingData) {
        tracker.trackAddToCart(trackingData)
      }
      const customEvent = new CustomEvent('buyButtonClicked', {
        bubbles: true,
        detail: event
      })
      document.body.dispatchEvent(customEvent)
    },
    trackViewShoppingCart() {
      tracker.trackSimpleEvent({ event: 'viewShoppingCart' })
    }
  }
}
</script>
