<template>
  <div
    class="c-shopping-cart-panel__recipes u-flex u-flex-justify-center u-flex-align-center"
  >
    <button
      class="c-button c-button--ghost"
      @click.prevent="undoClearCart"
    >
      <Icon
        :icon="iconUndo"
        :size="24"
        class="u-mr--xs"
        fill="black"
      />
      {{ model.undoButtonText }}
    </button>
  </div>
</template>

<script>
import { disablePageScroll } from 'scroll-lock'
import iconUndo from '@ds/svg/icons/bold/undo-bold.svg'
import Icon from '@/CVI/WebCore/components/Icon.vue'

export default {
  components: {
    Icon
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      iconUndo
    }
  },
  mounted() {
    disablePageScroll()
  },
  methods: {
    undoClearCart() {
      this.$emit('undo-clear-cart')
    }
  }
}
</script>
