<template>
  <div
    class="c-rating-static c-rating--s@mobile u-flex u-flex-align-center"
    :class="cssClass"
  >
    <div
      class="c-rating-static__stars"
      :class="{
        'u-mr--xs': size != 'small',
        'u-mr--xxs': size == 'small'
      }"
    >
      <div class="c-rating-static__unselected">
        <div
          class="c-rating-static__selected"
          :style="{
            width: width
          }"
        />
      </div>
    </div>
    <span
      v-if="showRatingCount"
      class="u-font-size-medium u-mr--xs"
    >
      ({{ count }})
    </span>
  </div>
</template>

<script>

const maxValue = 5

export default {
  props: {
    size: {
      type: String,
      default: ''
    },
    rating: {
      type: Number,
      default: 0
    },
    count: {
      type: Number,
      default: 0
    },
    showRatingCount: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      maxValue
    }
  },
  computed: {
    width() {
      return `${100 / this.maxValue * this.rating}%`
    },
    cssClass() {
      return [
        this.size == 'small' ? 'c-rating--s' : ''
      ]
    }
  }
}
</script>
