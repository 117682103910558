import axios from 'axios'

const language = document.documentElement.lang

export default {
  favoriteRecipes: {
    baseUrl: `/cvi/customerweb/api/${language}/favoriterecipes`,
    get(take = 10, skip = 0, isContentHubAsSource = false) {
      return axios.get(`${this.baseUrl}?take=${take}&skip=${skip}&isContentHubAsSource=${isContentHubAsSource}`)
    },
    post(recipeId) {
      return axios.post(this.baseUrl, {
        recipeId
      })
    },
    delete(recipeId) {
      return axios.delete(`${this.baseUrl}/${recipeId}`)
    }
  },
  recipes: {
    baseUrl: `/cvi/recipes/api/${language}/cards`,
    getLegacy(recipeIds) {
      const searchParams = new URLSearchParams();
      (recipeIds || []).forEach((id) => {
        searchParams.append('recipeIds', id)
      })
      return axios.get(`${this.baseUrl}/legacy`, { params: searchParams })
    },
    get(uids) {
      const searchParams = new URLSearchParams();
      (uids || []).forEach((uid) => {
        searchParams.append('uids', uid)
      })
      return axios.get(`${this.baseUrl}`, { params: searchParams })
    },
    post({
      uids, tagUids, excludedUids, take, skip = 0, query = null, cancelToken
    }) {
      return axios.post(`${this.baseUrl}`, {
        uids,
        tagUids,
        excludedUids,
        take,
        skip,
        query
      }, {
        cancelToken: cancelToken?.token
      })
    }
  },
  ingredients: {
    baseUrl: `/cvi/recipes/api/${language}/ingredients`,
    get(recipeId, portionCount) {
      return axios.get(`${this.baseUrl}/${recipeId}?portionCount=${portionCount}`)
    }
  },
  ratings: {
    baseUrl: `/cvi/recipes/api/${language}/ratings`,
    get(recipeId) {
      return axios.get(`${this.baseUrl}/${recipeId}`)
    },
    post(recipeId, rating, token) {
      return axios.post(`${this.baseUrl}/${recipeId}`, {
        rating, token
      })
    }
  }
}
