function createCropArea(centerX, centerY, width, height, imageWidth, imageHeight) {
  let x1 = 0
  let y1 = 0
  let x2 = 0
  let y2 = 0

  const scaleWidth = width * imageHeight / height
  const scaleHeight = height * imageWidth / width

  if (imageWidth > scaleWidth) {
    x1 = (centerX * imageWidth - scaleWidth * 50) / 100
    if (x1 < 0) {
      x1 = 0
      x2 = scaleWidth - imageWidth
    } else {
      x2 = x1 + scaleWidth - imageWidth
      if (x2 > 0) {
        x2 = 0
        x1 = imageWidth - scaleWidth
      }
    }
  }

  if (imageHeight > scaleHeight) {
    y1 = (centerY * imageHeight - scaleHeight * 50) / 100
    if (y1 < 0) {
      y1 = 0
      y2 = scaleHeight - imageHeight
    } else {
      y2 = y1 + scaleHeight - imageHeight
      if (y2 > 0) {
        y2 = 0
        y1 = imageHeight - scaleHeight
      }
    }
  }

  return `${x1},${y1},${x2},${y2}`
}

function calculateCrop(width, height, model) {
  const sourceWidth = model.width
  const sourceHeight = model.height

  if (sourceWidth == 0
      || sourceHeight == 0
      || !model.focusPointX
      || !model.focusPointY) {
    return null
  }

  const focalPointY = Math.round(sourceHeight * (model.focusPointY / 100))
  const focalPointX = Math.round(sourceWidth * (model.focusPointX / 100))
  const sourceAspectRatio = sourceWidth / sourceHeight

  //Calculate target aspect ratio from resizeSettings.
  let targetAspectRatio
  if (width > 0 && height > 0) {
    targetAspectRatio = width / height
  } else {
    targetAspectRatio = sourceAspectRatio
  }

  let x1 = 0
  let y1 = 0
  let x2
  let y2

  if (targetAspectRatio == sourceAspectRatio) {
    x2 = sourceWidth
    y2 = sourceHeight
  } else if (targetAspectRatio > sourceAspectRatio) {
    // the requested aspect ratio is wider than the source image
    const newHeight = Math.floor(sourceWidth / targetAspectRatio)
    x2 = sourceWidth
    y1 = Math.max(focalPointY - Math.round(newHeight / 2), 0);
    y2 = Math.min(y1 + newHeight, sourceHeight);
    if (y2 == sourceHeight) {
      y1 = y2 - newHeight
    }
  } else {
    // the requested aspect ratio is narrower than the source image
    const newWidth = Math.round(sourceHeight * targetAspectRatio)
    x1 = Math.max(focalPointX - Math.round(newWidth / 2), 0)
    x2 = Math.min(x1 + newWidth, sourceWidth)
    y2 = sourceHeight
    if (x2 == sourceWidth) {
      x1 = x2 - newWidth
    }
  }

  return `${x1},${y1},${x2},${y2}`
}

function calculateCropCms(model) {
  const sourceWidth = model.width
  const sourceHeight = model.height

  if (sourceWidth == 0
      || sourceHeight == 0
      || !model.focusPointX
      || !model.focusPointY) {
    return null
  }

  const focalPointY = Math.round(sourceHeight * (model.focusPointY / 100))
  const focalPointX = Math.round(sourceWidth * (model.focusPointX / 100))

  const x = Math.round((100 * focalPointX) / sourceWidth) / 100
  const y = Math.round((100 * focalPointY) / sourceHeight) / 100

  return `${x},${y}`
}

export default {
  Recipe: {
    // Copy of c# code from RdbClient.GetResizedPictureUrl method,
    // useFocusPoint is always true and removed from params
    resize(picture, width, height, options) {
      if (picture == null) {
        return null
      }
      let pictureUrl = ''
      // eslint-disable-next-line no-param-reassign
      if (typeof picture == 'string') {
        pictureUrl = picture
      } else {
        pictureUrl = picture.url
      }

      if (picture.url == null || !pictureUrl) {
        return pictureUrl
      }

      if (pictureUrl.includes('?')) {
        pictureUrl = pictureUrl.substring(0, pictureUrl.indexOf('?'))
      }

      if (width == 0) {
        // eslint-disable-next-line no-param-reassign
        ({ width } = picture)
      }

      if (height == 0) {
        // eslint-disable-next-line no-param-reassign
        ({ height } = picture)
      }

      const query = {}
      if (typeof picture != 'string') {
        if (picture.focusPointX != null && picture.focusPointY != null) {
          const contentAreaPoints = createCropArea(picture.focusPointX,
            picture.focusPointY, width, height, picture.width, picture.height)
          const cropArea = `(${contentAreaPoints})`
          query.crop = `${cropArea}`
        } else if (options == null || !options.mode) {
          query.mode = 'crop'
        }
      }

      if (width > 0) {
        query.w = width
      }

      if (height > 0) {
        query.h = height
      }

      if (options != null) {
        Object.entries(options).forEach(([key, value]) => {
          if (value == null && query.key) {
            delete query[key]
          } else {
            query[key] = value
          }
        })
      }

      const queryParams = []
      Object.entries(query).forEach(([key, value]) => {
        queryParams.push(`${key}=${value}`)
      })

      const queryString = queryParams.join('&')
      return `${pictureUrl}?${queryString}&preset=1`
    }
  },
  // Copy of c# code from ImageResizingNetImageResizer.Resize method
  ImageResizingNet: {
    resize(src, width, height, options) {
      if (src == null) {
        return null
      }

      let url
      let crop = null

      if (src.url != null) {
        url = src.url
        if (options?.mode === 'crop') {
          crop = calculateCrop(width, height, src)
        }
      } else {
        url = src
      }

      const qs = {}
      const queryIndex = url.indexOf('?')

      if (queryIndex >= 0) {
        const queryString = url.substring(queryIndex)
        queryString.split('&').forEach((part) => {
          const item = part.split('=')
          qs[item[0]] = decodeURIComponent(item[1])
        })

        url = url.substring(0, queryIndex)
      }

      if (width > 0) {
        qs.width = width
      }

      if (height > 0) {
        qs.height = height
      }

      if (options != null) {
        qs.mode = options.mode
        // We are not supporting presets for frontend now
        // qs.preset = options.preset
      }

      if (crop !== null) {
        qs.crop = crop
      }

      const queryParams = []
      Object.entries(qs).forEach(([key, value]) => {
        if (value) {
          queryParams.push(`${key}=${value}`)
        }
      })


      const queryString = queryParams.join('&')
      return `${url}?${queryString}`
    }
  },
  Squidex: {
    resize(src, width, height, options) {
      if (src == null || src.url == '') {
        return src
      }

      if (options && options.preset) {
        throw new Error('Preset option not supported')
      }

      const image = src.url
      const crop = options?.mode == 'crop'
      const mode = crop ? 'cropupsize' : 'max' // Max default parameter
      let resizedImageUrl = `${image}?width=${width}&height=${height}&mode=${mode}`

      if (image.endsWith('.jpg') || image.endsWith('.jpeg')) {
        resizedImageUrl += '&quality=90'
      }

      return resizedImageUrl
    }
  },
  Cms: {
    resize(image, width, height, options) {
      if (!image || !image.url) {
        return null
      }
      let url = ''
      let crop = null
      url = image.url

      if (options?.mode === 'crop') {
        crop = calculateCropCms(image)
      }

      const qs = {}
      const queryIndex = url.indexOf('?')

      if (queryIndex >= 0) {
        const queryString = url.substring(queryIndex)
        queryString.split('&').forEach((part) => {
          const item = part.split('=')
          qs[item[0]] = decodeURIComponent(item[1])
        })

        url = url.substring(0, queryIndex)
      }

      if (width > 0) {
        qs.width = width
      }

      if (height > 0) {
        qs.height = height
      }

      if (options != null) {
        qs.rmode = options.mode
        qs.preset = options.preset
      }

      if (crop !== null) {
        qs.rxy = crop
      }

      const query = []
      Object.entries(qs).forEach(([key, value]) => {
        if (value) {
          query.push(`${key}=${value}`)
        }
      })


      const queryString = query.join('&')

      return `${url}?${queryString}`
    }

  }
}
