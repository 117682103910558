<template>
  <picture>
    <Loader
      v-if="!loaded && showLoadState"
      class="u-flex u-mt--m u-ml--a u-mr--a"
      size="large"
      color="medium-gray"
    />
    <source
      v-if="wide"
      media="(min-width: 1200px)"
      :srcset="scrset(wide)"
    >

    <source
      v-if="desktop"
      media="(min-width: 992px)"
      :srcset="scrset(desktop)"
    >

    <source
      v-if="tablet"
      media="(min-width: 768px)"
      :srcset="scrset(tablet)"
    >

    <img
      :src="resize(mobile)"
      :srcset="scrset(mobile)"
      :alt="alt"
      :loading="lazy ? 'lazy' : 'auto'"
    >
  </picture>
</template>

<script>

import imageResizer from '@/CVI/WebCore/lib/imageResizer'
import Loader from '@/CVI/WebCore/components/Loader.vue'

export default {
  components: {
    Loader
  },
  props: {
    src: {
      type: [Object, String],
      required: true
    },
    resizer: {
      type: String,
      required: true
    },
    mobile: {
      type: Array,
      required: true
    },
    tablet: {
      type: Array,
      required: false,
      default: null
    },
    desktop: {
      type: Array,
      required: false,
      default: null
    },
    wide: {
      type: Array,
      required: false,
      default: null
    },
    options: {
      type: Object,
      required: false,
      default: null
    },
    alt: {
      type: String,
      required: false,
      default: ''
    },
    showLoadState: {
      type: Boolean,
      default: false
    },
    lazy: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loaded: false
    }
  },
  mounted() {
    if (this.showLoadState) {
      this.checkLoaded()
    }
  },
  methods: {
    resize(sizes) {
      const resizer = imageResizer[this.resizer]
      return resizer
        .resize(this.src, sizes[0], sizes[1], this.options)
    },
    scrset(sizes) {
      const defaultSize = this.resize(sizes)
      const multipliedSize = this.resize([sizes[0] * 2, sizes[1] * 2])
      return `${defaultSize}, ${multipliedSize} 2x`
    },
    checkLoaded() {
      this.$el.lastElementChild.addEventListener('load', () => {
        this.loaded = true
      })
    }
  }
}
</script>
